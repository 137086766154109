import React from "react"
import theme from "theme"
import { Theme, Text, Box, Image, Section, LinkBox } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Ana Sayfa | BIGGYM Fitness'a Hoş Geldiniz</title>
        <meta
          name={"description"}
          content={"Daha İyi Bir Size Yolculuğunuz Burada Başlıyor!"}
        />
        <meta
          property={"og:title"}
          content={"Ana Sayfa | BIGGYM Fitness'a Hoş Geldiniz"}
        />
        <meta
          property={"og:description"}
          content={"Daha İyi Bir Size Yolculuğunuz Burada Başlıyor!"}
        />
        <meta
          property={"og:image"}
          content={"https://uvixens.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://uvixens.com/img/icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://uvixens.com/img/icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://uvixens.com/img/icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="80px 0 80px 0" quarkly-title="Hero-19">
        <Override
          slot="SectionContent"
          width="100%"
          flex-direction="row"
          max-width="100%"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-around"
          lg-flex-direction="column"
          lg-overflow-y="hidden"
          lg-overflow-x="hidden"
        >
          <Box
            min-width="100px"
            min-height="100px"
            width="50%"
            lg-width="100%"
            lg-padding="0 50px 50px 50px"
            padding="0px 80px 0px 80px"
            sm-padding="0 15px 50px 15px"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="normal 700 56px/1.2 --fontFamily-sans"
              sm-font="normal 700 42px/1.2 --fontFamily-sans"
            >
              BIGGYM Fitness'a Hoş Geldiniz{"\n "}
            </Text>
            <Text
              margin="0px 0px 50px 0px"
              font="--lead"
              color="#8b9197"
              lg-margin="0px 0px 30px 0px"
            >
              Her antrenmanın dönüşüm için bir fırsat olduğu BIGGYM Fitness'a
              hoş geldiniz. Fitness yolculuğunuza başlamanın veya devam etmenin
              göz korkutucu olabileceğini biliyoruz, bu yüzden sadece egzersizle
              ilgili değil, destekleyici bir topluluk oluşturmakla ilgili bir
              alan yarattık. BIGGYM Fitness'ta, hedeflerinize ulaşmanıza
              yardımcı olmak için cesaretlendirmenin ve kişiselleştirilmiş
              ilginin gücüne inanıyoruz. İster yeni başlayan ister deneyimli bir
              sporcu olun, kapılarımız kendinizin en iyi versiyonu olmanıza
              yardımcı olmak için açıktır.
            </Text>
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              sm-flex-direction="column"
              sm-align-items="stretch"
            />
          </Box>
          <Image
            src="https://uvixens.com/img/1.jpg"
            display="block"
            width="50%"
            object-fit="cover"
            lg-width="100%"
            object-position="0 0"
            padding="15px 0px 15px 15px"
            border-radius="25px 0 0 25px"
            background="--color-lightD1"
            sm-margin="0px 0px 0px 15px"
            lg-margin="0px 0px 0px 25px"
          />
        </Box>
      </Section>
      <Section
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Box
          margin="0px 0px 32px 0px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            font="--headline2"
            color="--dark"
            text-align="center"
            md-font='normal 700 30px/1.2 "Source Sans Pro", sans-serif'
            margin="16px 0px 0px 0px"
          >
            Neden BIGGYM Fitness'ı Seçmelisiniz?
          </Text>
        </Box>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://uvixens.com/img/2.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Kişiselleştirilmiş Egzersizler: Her vücut benzersizdir ve
                fitness planlarımız bireysel ihtiyaçlarınıza göre uyarlanarak
                her seanstan en iyi şekilde yararlanmanızı sağlar.
              </Text>
            </Box>
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              lg-padding="0px 0px 0px 0px"
              sm-width="100%"
            >
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Topluluk Ruhu: Topluluk odaklı bir ortamda egzersiz yapmanın
                getirdiği motivasyon artışını yaşayın. Sadece bir spor salonuna
                katılmıyorsunuz - bir aileye katılıyorsunuz.
              </Text>
            </Box>
          </Box>
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://uvixens.com/img/3.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://uvixens.com/img/4.jpg"
              border-radius="24px"
              max-width="100%"
              max-height="356px"
              width="100%"
              object-fit="none"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              lg-padding="0px 0px 0px 0px"
              sm-width="100%"
            >
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Gelişmiş Ekipmanlar: En son fitness teknolojisi ve ekipmanlarına
                erişim sayesinde antrenmanlarınız her zaman taze, zorlu ve
                etkili olacaktır.
                <br />
                <br />
                Uzman Rehberliği: Sertifikalı eğitmenlerden oluşan ekibimiz, her
                adımda uzman tavsiyesi ve desteği sunarak kendini başarınıza
                adamıştır.
              </Text>
            </Box>
          </Box>
        </LinkBox>
      </Section>
      <Components.Contacts />
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
